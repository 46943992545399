import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/drupal-sydney-2021",
  "date": "15 April 2021",
  "title": "Sydney Drupal 2021",
  "summary": "This is a video recording from the Drupal Sydney Meetup where Nick Schuch gave an introduction to the Skpr platform",
  "author": "Nick Schuch",
  "tag": "Presentation",
  "tagColor": "green",
  "tags": [{
    "name": "meetup"
  }, {
    "name": "presentation"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Overview`}</h2>
    <p>{`This is a video recording from the Drupal Sydney Meetup where Nick Schuch gave an introduction to the Skpr platform.`}</p>
    <p>{`This talk is a great resource for getting to know the Skpr platform, its goals and some of its key features.`}</p>
    <h2>{`Key Points`}</h2>
    <ul>
      <li parentName="ul">{`Highly available hosting solution backed by Kubernetes and AWS Managed Resources`}</li>
      <li parentName="ul">{`Built to empower developers`}</li>
      <li parentName="ul">{`Package, Deploy and Configure your applications via our easy to use commandline interface`}</li>
      <li parentName="ul">{`Integrates with any CI/CD product/solution`}</li>
    </ul>
    <h2>{`Recording`}</h2>

    <YouTube videoId="440IcbK_QZQ" opts={{
      height: '420',
      width: '100%'
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      